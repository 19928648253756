import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import {
  Box,
  Divider,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography
} from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import AppsIcon from '@mui/icons-material/Apps';
import MenuIcon from "@mui/icons-material/Menu";
import {roles, logo } from "../../config";

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  boxShadow: "none",
  borderBottom: "2px solid #eeeeee",
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.shortest,
  }),
  ...(open && {
    //marginLeft: 250,
    width: '100%',  //`calc(100% - ${250}px)`,
  }),
}));

export const Topbar = ({
  drawerIsOpen = false,
  user = {},
  onToggleDrawer,
  age,
  formatting,
  ...props
}) => {


  return (
    <AppBar position="fixed" open={drawerIsOpen} >
      <Toolbar
        sx={{
        pr: "24px",
          color: "#ffffff",
          backgroundColor: '#05333A' ,
          border: '0px'
      }}
        >
        <IconButton
          edge="start"
          aria-label="open drawer"
          onClick={onToggleDrawer}
          sx={{
          marginRight: "36px",
            color: '#fff' ,
            ...(drawerIsOpen && { display: "none" }),
          }}
          >
          <AppsIcon />
        </IconButton>
        <Box>
          <div
            style={{
            fontWeight: 900,
              fontSize: 24,
              letterSpacing: ".25px",
              marginLeft: "15px",
              border: '0px',
              color:'#fff'
          }}
            >
            <img alt="burger" src={logo.sidebarLogo} height="35px" />
          </div>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <span
          style={{
          marginRight: "20px",
            fontSize: "16px",
            letterSpacing: ".25px",
            fontWeight: 500,
            color:'#fff'
        }}
          >
          {user.name}
        </span>
        <UserMenu {...props} formatting={formatting}/>
      </Toolbar>
    </AppBar>
    );
};

const UserMenu = ({
  userProfile = {},
  user = {},
  handleSwitchGroup,
  handleShowBorder,
  formatting
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  let group;
  if (userProfile?.user?.group) {
    group = userProfile?.user?.group;
  } else {
    group = userProfile?.app?.group;
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <div>
      <MenuIcon
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      />

      <Menu
        sx={{ mt: "45px", color: '#fffff' }}
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        >
        <Divider />
        {userProfile?.app?.group === 'admin' && (
          <Typography gutterBottom fontWeight={'bold'} marginLeft='15px'>Current Role: {group} </Typography>
          )}
        <Divider />
        {userProfile?.app?.group === 'admin' && roles.map((role) => (
            <MenuItem key={role} onClick={() => handleSwitchGroup(role)}>
        <Typography>Impersonate {role}</Typography>
            </MenuItem>
            ))}
        <Divider />
        <Typography gutterBottom fontWeight={'bold'} marginLeft='15px'>{userProfile?.user?.name}</Typography>
        <LogoutMenuItem />
        <Divider />
        {userProfile?.app?.group === 'admin' && (
          <MenuItem onClick={handleShowBorder}>
            <Typography>Highlight Retool</Typography>
          </MenuItem>
          )}
      </Menu>

    </div>
    );
};

const LogoutMenuItem = ({ userProfile, handleSwitchGroup }) => {
  const { logout } = useAuth0();

  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
  }

  return (
    <MenuItem key="auth0-logout">
      <Button
        style={{ color: "#000000", textTransform: "capitalize"}}
        onClick={handleLogout}
        >
        Log Out
      </Button>
    </MenuItem>
    );
};

export default Topbar;
