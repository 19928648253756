exports.deployOnLocalhost = false;

// To get your Auth0 Domain, and ClientID, see this guide https://auth0.com/docs/quickstart/spa/react#configure-auth0

exports.logo = {
  image: "https://sandbox-portal-85be944.s3.amazonaws.com/assets/Boost-Logo-Gradient-400px.png",
  signinlogo: "https://sandbox-portal-85be944.s3.amazonaws.com/assets/boost-logo-graphic.png",
  sidebarLogo: "https://sandbox-portal-85be944.s3.amazonaws.com/assets/Boost-logo-full-white-gradient.png",
  favicon: "https://sandbox-portal-85be944.s3.amazonaws.com/assets/Favicon.png"
};

exports.auth = {
  tokenDuration: "28800s",
  REACT_APP_AUTH0_CUSTOM_DOMAIN: 'auth.boostinsurance.io',
  REACT_APP_AUTH0_DOMAIN: 'boostinsurance-io.us.auth0.com',
  REACT_APP_AUTH0_CLIENT_ID: 'WYLyKqwJ2SHhom45tNQf8azqZD6u8lRC',
  REACT_APP_AUTH0_SCOPE: "read:current_user update:current_user_metadata",
};

exports.apiServer = {
  API_HOST:'https://api.portal.boostinsurance.io'
}

exports.roles = ['boost', 'reinsurer', 'partners', 'admin'];

exports.homepage = {
  sidebarList: [
    {
      title: "Portfilo Insights",
      retoolAppName: "reinsurePortal",
      appId: "cd0f8f08-2af8-11ee-9d6a-c732c876dae6",
      icon: "assessment",
      slug: "",
      groups: ["reinsurer","partners","boost", "admin"],
    },
    {
      title: "Developer Portal",
      retoolAppName: "testPage",
      appId: "a320c89a-2a5f-11ee-8a05-7b82b7853003",
      icon: "engineering",
      slug: "developer",
      groups: ["boost", "partners", "admin"],
    },
  ],
};

exports.theme = {
  palette: {
    primary: {
      main: "#ffffff",
      light: "#E06363",
      dark: "#972A2A",
      contrastText: "#ffffff",
    },
  },
  typography: {
    fontFamily: "Roboto",
  },
};

exports.formattingPreferences = {
    backgroundColor: '#ffffff',
    text: '#080928',
    elevation: 1
}